.feedback-widget-container {
    .feedback-widget {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }

    .feedback-button {
        background: linear-gradient(135deg, #6ec1e4, #007bff);
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px 0 0 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s;
    }

    .feedback-button:hover {
        background: linear-gradient(135deg, #5aa0c8, #0056b3);
    }

    .feedback-icon-button {
        background: linear-gradient(135deg, #6ec1e4, #007bff);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: background-color 0.3s;
    }

    .feedback-icon-button:hover {
        background: linear-gradient(135deg, #5aa0c8, #0056b3);
    }

    .feedback-dialog {
        position: fixed;
        right: 20px;
        bottom: 60px;
        background-color: white;
        border-radius: 5px;
        width: 300px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        z-index: 1001;
    }

    .dialog-content {
        position: fixed;
        width: 350px;
        right: 0;
        bottom: 0;
        padding: 20px;
        background: white;
        border: 1px solid lightgrey;
        border-radius: 5px;
    }

    .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        font-size: 1.2em;
        color: #333;
    }

    .shortcut-hint {
        position: absolute;
        bottom: 0px;
        right: 20px;
        font-size: 0.8em;
        color: #666;
    }

    .loading-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    textarea {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 16px;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    button[type="submit"] {
        background: linear-gradient(135deg, #a8e063, #28a745);
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
    }

    button[type="button"] {
        background: linear-gradient(135deg, #f76c6c, #dc3545);
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
    }

    button[type="submit"]:hover {
        background: linear-gradient(135deg, #8ccf4d, #218838);
    }

    button[type="button"]:hover {
        background: linear-gradient(135deg, #e55a5a, #c82333);
    }
}