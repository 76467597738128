.people-table {
  tbody tr td{
      text-transform: capitalize !important;
    }
    .people-header-row {
      // position: sticky;
      // top: 50px;
      z-index: 999;
      .people-cell {
        background-color: #2bb670;
        font-weight: bold;
        color: white;
        text-align: left;
        font-size: 16px;
        padding: 5px;
        height: 60px;
        border-left: 1px solid white;
        line-height: 1;
      }
    }
    .people-row {
      .people-cell {
        // background-color: white;
        font-size: 16px;
        padding: 5px;
        // height: 70px;
      }
    }
    .people-row:hover {
      background-color: #EEF6F2;
    }
    .people-cell {
      min-width: 90px;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    } 
    .people-body-column:nth-child(1){
      font-weight: bold;
      height: inherit !important;
      font-size: 20px !important;
      padding: 10px 5px !important;
    }
    .pipeline-body-column:nth-child(1){
      font-weight: bold;
      height: inherit !important;
      font-size: 20px !important;
      padding: 10px 5px !important;
    }
    //pipeline-body-column:nth-child last 4 columns
    .pipeline-body-column:nth-child(4), .pipeline-body-column:nth-child(5), .pipeline-body-column:nth-child(6){
      max-width: 30px !important;
    }
    .people-body-column{
      height: inherit !important;
      font-size: 20px !important;
      padding: 10px 5px !important;
    }
    .people-body-row:hover{
      background-color: #eee;
      cursor: pointer;
    }
    .brands-row{
      .people-body-column:nth-child(1) a{
        color: #000000;
      }
    }
    .brands-row{
      .people-body-column:nth-child(1) a:hover{
        color: #000000;
        text-decoration: underline;
      }
    }
    .brands-row:hover{
      background-color: #eeeeee !important;
    }
    .Phase_1, .Phase_2, .Phase_3, .Approved{
      padding: 0 !important;
      .progress {
        border-radius: 0;
      }
    }
    .bg-phase1 .progress {
      background-color: #FFDA6B; /* Softer yellow */
    }
    
    .bg-phase2 .progress {
        background-color: #FFB84D; /* Softer orange */
    }
    
    .bg-phase3 .progress {
        background-color: #FF866D; /* Softer coral/red */
    }
    
    .bg-approved .progress {
        background-color: #6FCF97; /* Softer green */
    }
  
  

  }
  