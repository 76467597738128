.expandable-table {
  width: 100%;
  max-width: 1100px;
  @media (max-width: 599px) {
    width: 100%;
  }
  .expandable-header-row {
    background-color: #2bb670;
    border-radius: 10px 10px 0px 0px;
    // sticky header
    position: sticky;
    top: 57px;
    z-index: 99;
    // normal cursor on hover
    cursor: default !important;
    .expandable-cell {
      color: white;
      font-weight: bold;
    }
    .expandable-cell:nth-child(2) {
      text-align: right;
      margin-right: 24px;
    }
  }
  .expandable-footer-row {
    border-top: 2px solid #555;
    padding-top: 2px;
    .expandable-cell {
      font-weight: bold;
    }
  }
  .expandable-row,
  .expandable-footer-row,
  .expandable-header-row {
    width: 100%;
    display: flex;
  }
  .expandable-row.appr-subheading {
    // background-color: #c5ecda;
    font-weight: bold;
    border-bottom: 1px solid black;
  }
  .expandable-row.appr-subheading.grouping-row {
    // background-color: #c5ecda;
    border: 1px solid black;
    border-top: none;
  }
  .expandable-row.expand-row {
    background-color: white;
    padding-left: 10px;
  }
  // .expandable-row.expand-row.expanded {
  //   background-color: #eef6f2;
  // }
  .expandable-row.expand-second-row {
    background-color: #eef6f2;
    padding-left: 30px;
    .expandable-cell{
      font-size: 22px;
    }
  }
  .expandable-row.expand-second-row.expanded {
    background-color: #c5ecda;
    border-bottom: dotted;
  }
  .expandable-row.expand-middle-row {
    background-color: #eef6f2;
    padding-left: 15px;
    .expandable-cell{
      font-size: 22px;
    }
    .expandable-cell:nth-child(1){
      padding-left: 35px;
    }
  }
  .expandable-row.expand-middle-row.expanded {
    background-color: #c5ecda;
    border-bottom: dotted;
  }
  .expandable-row.leaf-row {
    font-weight: bold;
    background-color: white;
    padding-left: 15px;
    .expandable-cell:nth-child(1){
      padding-left: 70px;
    }
    .expandable-cell{
      font-size: 22px;
    }
  }
  .expandable-row:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .expandable-cell {
    padding: 8px;
    font-size: 1.3rem;
  }
  .expandable-cell:nth-child(1) {
    width: 70%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(2) {
    width: 30%;
    margin-right: 24px;
  }
  .expandable-cell:nth-child(1) small{
    margin-left: 24px;
  }
}
.expandable-table-phase {
  .expandable-cell:nth-child(1) {
    width: 50%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(2) {
    width: 50%;
    margin-right: 24px;
  }
  .active-trials-phase{
    background: #2bb670;
    position: sticky;
    top: 105px;
    z-index: 999;
  }
  .active-trials-phase:hover{
    background: #2bb670;
  }
}
.players-summary-table {
  .expandable-table {
    width: 100%;
    max-width: 100%;
  }
  .expandable-cell:nth-child(1) {
    text-align: left;
    width: 44%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(2) {
    width: 14%;
    text-align: center !important;
    margin-right: 0px !important;
  }
  .expandable-cell:nth-child(3) {
    width: 16%;
    text-align: center !important;
    margin-right: 0px !important;
  }
  .expandable-cell {
    text-align: center;
    width: 14%;
    margin-right: 0px !important;
  }
  .expandable-row.expand-middle-row {
    padding-left:10px;
    .expandable-cell:nth-child(1) {
      padding-left: 40px;
    }
  }
  .expandable-row.expand-middle-row.expanded {
    padding-left:10px;
  }
  .expandable-row.leaf-row {
    padding-left:10px;
    .expandable-cell:nth-child(1) {
      padding-left: 65px;
    }
  }
  .expandable-row.leaf-row.expanded {
    padding-left:10px;
  }
}
.deals-summary-table {
  .expandable-table{
    width: 100%;
    max-width: 100%;
  }
  .expandable-cell:nth-child(1) {
    text-align: left;
    width: 35%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(2) {
    text-align: left;
    width: 30%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(3) {
    text-align: left;
    width: 15%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(4) {
    text-align: left;
    width: 160px;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(5) {
    text-align: right;
    width: 10%;
    padding-left: 10px;
  }
}
.physicians-table{
  .expandable-table{
    width: 100%;
    max-width: 100%;
  }
  .expandable-table .expandable-cell a{
    color: #000000;
  }
  .expandable-table .expandable-cell a:hover{
    color: #000000;
    text-decoration: underline;
  }
  .physicians-expandable-cell:nth-child(1) {
    width: 45% !important;
    margin-right: 10px !important;
  }
  .physicians-expandable-cell:nth-child(2) {
    width: 40% !important;
    margin-right: 0px !important;
    margin-right: 24px;
    min-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .physicians-expandable-cell:nth-child(3) {
    width: 15% !important;
    margin-right: 24px !important;
  }
  .expandable-cell:nth-child(1) {
    width: 80%;
    padding-left: 10px;
  }
  .expandable-cell:nth-child(2) {
    width: 20%;
    margin-right: 24px;
  }
  .expandable-cell:nth-child(3) {
    margin-right: 24px;
  }
  .small-70{
    font-size: 70%;
  }
}
.expandable-cell{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}