// Here you can add other styles
body {
  font-weight: 400 !important;
}
.my-custom-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
  font-size: medium;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

//spinner

//spiner

.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.loading div {
  position: absolute;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
.disease-card-scrollbar {
  position: relative;
  // height: 200px;
  overflow: auto;
  font-size: medium;
  // text-align: center;
}
.scrollable-element {
  scrollbar-width: 10px;
}
sup {
  cursor: pointer !important;
  color: blue !important;
}
.my-component-popover {
  transform: none !important;
  overflow: visible !important;
  max-height: 300px !important;
  min-height: 100px !important;
  // height: 100% !important;
  padding: 114px 0px 0px !important;

  z-index: 999999;
  font-size: 14px !important;
  overflow-y: auto !important;
  & > div > div:first-child {
    border-bottom-color: white !important;
    top: 1px !important;
    z-index: 999999 !important;
  }

  & > div > div > div:first-child {
    border-bottom-color: #ccc !important;
  }

  & > div > div {
    padding: 0 !important;
  }

  .my-component-popover-content {
    background: white;
    padding: 10px;
    // border: 1px solid #ccc;
    // box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05),
    //   10px 0px 15px rgba(0, 0, 0, 0.05), -10px 0px 15px rgba(0, 0, 0, 0.05),
    //   0px -5px 40px rgba(0, 0, 0, 0.05);
  }
}
.heading {
  font-size: 18px;
  color: rgb(37, 34, 34);
}
.heading:hover {
  color: rgb(89, 89, 247);
}
.listDiseaseShadow:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

body {
  /* We need to assaign this CSS Custom property to the body instead of :root, because of CSS Specificity and codepen stylesheet placement before loaded CKE5 content. */
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.signup-box {
  border: 1px solid #ddd;
  width: 700px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
}
.title {
  font-weight: 600;
  margin-top: 0;
}
.err {
  font-size: small;
  color: red;
}
.asterisk {
  font-size: small;
  color: red;
}
.c-sidebar-nav-item:has(a.disabled) {
  cursor: not-allowed !important;
  opacity: 0.6;
}
.c-active {
  background-color: darkgray !important;
}

.c-sidebar-nav-link {
  padding: 10px 1rem;
}

.addUserAdmin {
  margin-bottom: 0px !important;
}

.subsections {
  background-color: #3c4b64;
  // height: 30px;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .PrintSection {
    margin: 20mm;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

// @media print {
//   .page-break {

//     margin-top: 1rem;
//     display: block;
//     page-break-before: auto;
//   }
// }

@page {
  size: auto;
  margin: 0mm;
}

.MuiInput-underline:before {
  content: none !important;
}
.MuiInput-underline::after {
  content: none !important;
}

h2 {
  margin-bottom: 0 !important;
}

.cardFooter {
  padding: 12px 15px !important;
  border-top: unset !important;
}

.cardFooterMobile {
  padding: 10px 10px !important;
  border-top: unset !important;
}

.MuiAccordion-root:before {
  height: 0px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #32cd32 !important;
}

// Navbar change to Light mode
@media (max-width: 599px) {
  .c-header-toggler-icon {
    height: 35px;
  }
}
.c-sidebar {
  background-color: white;
  border-right: 1px solid #eee !important;
}
.c-sidebar-nav-link:hover {
  background-color: #ddd !important;
}
.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url();
}
.c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-sidebar-nav-link {
  color: black !important;
  font-weight: 400 !important;
  font-size: 18px;
}
.c-sidebar-nav-icon,
.c-sidebar-nav-icon::after {
  color: black !important;
}
.subsections {
  background-color: #eee;
}

.MuiBackdrop-root {
  backdrop-filter: blur(3px);
}

.trial-table-head {
  // background-color: #393E46 !important;
  .trial-table-cell {
    // color: white !important;
  }
}

.trial-table-row-head {
  .trial-table-cell {
    // background-color: #EEEEEE !important;
  }
}
.trial-table-row {
}
.trial-table-cell {
  padding: 5px 0;
}

// .MuiPaper-root {
//   min-width: 70%;
// }

.diseases-modal-header {
  text-align: center;
  padding: 10px;
  z-index: 999;
  background-color: #3c4b64;
  font-size: 34px;
  font-weight: 800;
  color: white;
  position: fixed;
  display: table;
}
.diseases-modal-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  i {
    font-size: 24px;
    padding: 5px 10px;
    border: 1px solid;
  }
}
.diseases-modal-column {
  .columns-0-3,
  .columns-1-3,
  .columns-2-3 {
    width: 33%;
  }
  .columns-0-2,
  .columns-1-2 {
    width: 50%;
  }
  .columns-0-1 {
    width: 100%;
  }

  padding: 80px 20px 30px 30px;
  p {
    padding-top: 10px;
    font-weight: 400;
  }
  a:hover {
    text-decoration: underline;
    p {
      color: blue !important;
    }
  }
}
.diseases-modal-column-mobile {
  padding: 0 10px;
  p {
    font-weight: 400;
  }
  a:hover {
    text-decoration: underline;
    p {
      color: blue !important;
    }
  }
}
.home-list-more-btn {
  font-size: 14px;
  cursor: pointer;
  padding: 0px 10px;
  border-radius: 20px;
  border: 1px solid grey;
}
.home-list-more-btn:hover {
  color: white;
  background: grey;
}
.modal-table th,
td {
  font-size: 18px;
}
.modal-table-deals th,
td {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}
.modal-table-deals th:first-child {
  background-color: #eeeeee;
}
.search-bar-global input {
  font-size: 1.5rem;
  padding: 10px;
}
.search-bar-global fieldset {
  top: 0;
}
.select-bar-global div {
  font-size: 1.5rem;
  padding: 14px 10px;
  min-height: initial;
}
.select-bar-global fieldset {
  top: 0;
}
.filter-autocomplete div div {
  padding: 0px !important;
}
.filter-autocomplete div div input {
  font-size: 1.5rem;
}
.filter-autocomplete div div fieldset {
  top: 0;
}
.filter-autocomplete div label {
  top: -15px !important;
  font-size: 1.4rem;
  left: -15px;
  transform: translate(20px, 28px) scale(1);
}
tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
.players-table tr th {
  font-size: 22px;
  font-weight: bold;
  background: #2bb670;
  color: #ffffff;
}
.players-table tr td {
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-transform: capitalize;
}
.players-table tr:last-child {
  border-bottom: 2px solid #555;
}
.table {
  color: #000000 !important;
}
.company-logo {
  max-width: 50px;
  width: 100%;
}
.modal-data p {
  font-size: 18px;
}
.modal-data-brand {
  font-size: 18px;
}
.modal-data-brand .table tr th {
  width: 20%;
  font-size: 18px;
  padding-top: 5px;
  padding-left: 0px;
  padding-bottom: 5px;
  font-weight: 500;
}
.modal-data-brand .table tr td {
  font-size: 18px;
  padding-top: 5px;
  padding-left: 0px;
  padding-bottom: 5px;
  color: #000000;
  font-weight: 400;
}
.modal-data-brand .table {
  margin-bottom: 0px;
}
.table-scroll {
  overflow-x: scroll;
}
.table-scroll .people-table .people-header-row {
  top: 0px;
}
.btn-group a {
  color: #2bb670 !important;
}
.btn-group {
  border-color: #2bb670 !important;
}
.btn-active {
  background-color: #2bb670 !important;
  color: white !important;
  border-color: #2bb670 !important;
}
.btn-active a {
  color: white !important;
}
.subsections a {
  padding-left: 70px !important;
}
.c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
  height: 9px;

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23000' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  height: 9px;

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23000' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.dashboard-disease-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin: 20px 10px;
  border-radius: 3;
  padding: 10px;
  border: 1px solid black;
  color: black;
  font-weight: bold;
}
.dashboard-disease-link:hover {
  background-color: grey;
  color: white;
}
.modal-table-ceo .font-weight-bold {
  width: 25% !important;
}
.admin-table table tr td {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  min-width: 60px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.admin-table table tr th {
  font-size: 18px;
  color: #000000;
}
.admin-table table tr td label {
  font-size: 18px !important;
  color: #000000 !important;
  font-weight: 400;
}
.admin-table table tr td input {
  font-size: 18px !important;
  color: #000000 !important;
}
.admin-table {
  .table-responsive {
    overflow: unset !important;
    table thead {
      position: sticky;
      top: 55px;
      z-index: 999;
    }
    table thead tr th {
      background: #a9a9a9;
    }
  }
}
.admin-page-title h4 {
  font-weight: bold;
  padding: 0;
}
.btn-primary {
  font-weight: bold !important;
  font-size: 14px !important;
}
.admin-dropdown .dropdown {
  width: 100%;
}
.admin-dropdown .dropdown .dropdown-toggle {
  box-shadow: 0 0 0;
  margin: 0;
  width: 100%;
  background-color: #fff !important;
  color: #000000;
  border: 1px solid #cac7c7;
  border-radius: 5px;
}
.admin-dropdown .dropdown .dropdown-toggle:hover {
  background-color: #0b51c5 !important;
  color: #ffffff;
}
.fixed-btn-admin {
  position: fixed;
  bottom: 25px;
  right: 25px;
}
.fixed-btn-admin-clone {
  position: fixed;
  bottom: 25px;
  right: 160px;
}
.input-without-border {
  background: #ffffff !important;
  border: inherit !important;
}
.single-company-summary {
  .load-more-btn {
    display: none !important;
  }
}
.text-transform-none {
  text-transform: none !important;
}
.admin-icons {
  margin-left: 5px !important;
}
.people-table {
  a:hover {
    text-decoration: underline;
  }
}
input::placeholder {
  color: #c4c2c2 !important;
}
#popoverPosition ul li {
  font-size: 18px !important;
}
#popoverPosition ul li a {
  font-size: 18px !important;
}
.dymamic-people-table tr td {
  font-size: 20px !important;
  font-weight: 400;
}
.technology-people-table tr td {
  font-size: 20px !important;
  font-weight: 400;
}
.MuiAvatar-colorDefault {
  background-color: #ffffff !important;
}
.MuiChip-root .MuiChip-avatar {
  color: #000000 !important;
}
.MuiChip-root .MuiChip-avatarSmall {
  font-size: 18px !important;
  font-weight: 700;
}
.physician-name-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 14px;
  padding: 10px;
  border: 1px solid black;
  color: black;
  // font-weight: bold;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}
.physician-name-link:hover {
  background-color: #525252;
  color: white;
}
.physician-active {
  background-color: #525252;
  color: white;
}
.first-letter-capitalize::first-letter {
  text-transform: uppercase;
}
.first-letter-capitalize tr th::first-letter {
  text-transform: uppercase;
}
.first-letter-capitalize tr td::first-letter {
  text-transform: uppercase;
}
.description {
  // width: 200px;
  white-space: normal !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
}
.trials-table table tr td:first-child {
  width: 10% !important;
}
.trials-table table tr td:first-child {
  width: 10% !important;
}
.trials-table table tr th:last-child {
  width: 20% !important;
}
.trials-table table tr td:last-child {
  width: 20% !important;
}
.physicians-table table tr td:first-child {
  width: 30% !important;
}
.physicians-table table tr td:first-child {
  width: 30% !important;
}
.MuiAlert-message {
  text-transform: capitalize;
  font-size: 18px;
}
.readonly {
  background-color: #f4f7f8 !important;
}
.search-bar-global {
  padding: 10px !important;
  font-size: 1.5rem !important;
}
.text-dark {
  color: #000000 !important;
}
@media (max-width: 940px) {
  .c-header-toggler-icon {
    height: 55px !important;
  }
}
